import Vue from "vue"
import {
  RecycleScroller,
  DynamicScroller,
  DynamicScrollerItem,
} from "vue-virtual-scroller"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"

Vue.component("RecycleScroller", RecycleScroller)
Vue.component("DynamicScroller", DynamicScroller)
Vue.component("DynamicScrollerItem", DynamicScrollerItem)
