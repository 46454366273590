import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { Oauth2Scheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login",
    "logout": "/",
    "home": "/",
    "callback": "/login"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "oho_auth.",
    "options": {
      "path": "/",
      "domain": "hoh.meeboon.dev"
    }
  },
  "localStorage": false,
  "defaultStrategy": "facebook"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // facebook
  $auth.registerStrategy('facebook', new Oauth2Scheme($auth, {
  "endpoints": {
    "authorization": "https://facebook.com/v2.12/dialog/oauth",
    "userInfo": "https://graph.facebook.com/v20.0/me?fields=about,name,picture{url},email,birthday"
  },
  "clientId": "265344702138419",
  "scope": [
    "public_profile",
    "email",
    "public_profile",
    "email"
  ],
  "name": "facebook"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
