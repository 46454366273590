import _ from "lodash"
import ep from "~/api/endpoint"

const state = () => ({
  show_contact_profile_dialog: false,
  has_some_contact_profile_field_updated: false,
  routeNextFn: null,
  auto_detect_data: {},
  info_fields_order: [],
})

const mutations = {
  setShowContactProfileDialog(state, boolean) {
    state.show_contact_profile_dialog = boolean
  },
  setHasSomeContactProfileFieldUpdated(state, boolean) {
    state.has_some_contact_profile_field_updated = boolean
  },
  setContactProfileRouteNextFn(state, data = null) {
    state.routeNextFn = data
  },
  setContactProfileAutoDetectData(state, data) {
    state.auto_detect_data = data
  },
  resetContactProfileAutoDetectData(state) {
    state.auto_detect_data = {}
  },
  setContactProfileInfoFieldsOrder(state, data) {
    state.info_fields_order = data
  },
  resetContactProfileInfoFieldsOrder(state) {
    state.info_fields_order = []
  },
}

const actions = {
  handleBeforeLeaveEditContactProfile({ state, commit }, { routeNextFn }) {
    const has_some_updated = _.get(
      state,
      "has_some_contact_profile_field_updated"
    )

    if (has_some_updated) {
      commit("setShowContactProfileDialog", true)
      commit("setContactProfileRouteNextFn", routeNextFn)
    } else {
      routeNextFn()
    }
  },
  async getContactProfileInfoFieldsOrder({ rootState, state, commit }) {
    const biz_id = _.get(rootState, "oho_member.member.business_id")
    try {
      const res = await this.$ohoMemberApi.$get(
        `${ep.ordered_contact_profile}/${biz_id}`
      )
      if (!_.isEmpty(res)) {
        const indexes = _.get(res, "indexes", [])
        commit("setContactProfileInfoFieldsOrder", indexes)
      }
    } catch (error) {
      this.$logger.error(error)
    }
  },
}

export default { state, mutations, actions }
