
import _ from "lodash"
import moment from "moment"

export default {
  data() {
    return {
      current_show: 0,
      previous_data: {},
      next_data: {},
      dialog: false,
      announcements: [],
      active_announcements: [],
      read_announcement_ids: new Set(),
    }
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        // Activate on page transition
        this.refreshActiveAnnouncement(this.announcements, this.$route)
      },
    },
  },
  async mounted() {
    // Load all announcements
    await this.getAnnouncement()
    // Activate on browser reload
    this.refreshActiveAnnouncement(this.announcements, this.$route)
  },
  methods: {
    async fetchAnnouncement() {
      try {
        if (!process.env.content_whatsnew_url) return []
        return await this.$axios.$get(`${process.env.content_whatsnew_url}`)
      } catch (error) {
        this.$logger.error(error)
        return []
      }
    },
    async getAnnouncement() {
      const announcements = await this.fetchAnnouncement()
      this.next_data = { ok: true }
      this.announcements = announcements.map((announce) => {
        if (announce.path) {
          announce.paths = announce.path.split("\n").map((input_path) => {
            const p = input_path.split("?")
            const basePath = p[0]
            let regexp
            if (!basePath || basePath === "*") {
              regexp = /.*/
            } else {
              // Path pattern that we supports
              // - /smartchat
              // - /smartchat/*
              // - /automation/team-setting
              // - /automation/team-setting/*
              // - /case/*/stats
              // - /case/*/stats/*
              const basePathSplits = basePath
                .replace(/^[/]/, "")
                .replace(/[/]$/, "")
                .split("/")
              const cleanPaths = basePathSplits
                .map((str, index, list) => {
                  if (str === "*" && index < list.length - 1) return "/(.+)"
                  else if (str === "*" && index === list.length - 1)
                    return "(/.+)?"
                  else return `/${str}`
                })
                .flat()
              const regexpPaths = [
                "^/business/[0-9a-f]{24}",
                ...cleanPaths,
                "$",
              ]
              const regexpString = regexpPaths.join("")
              regexp = new RegExp(regexpString, "gmi")
            }
            // const queryString = p[1]
            // const query = qs.parse(queryString)
            return {
              regexp,
              // query,
            }
          })
        } else {
          announce.paths = []
        }
        return announce
      })
    },
    validateAnnouncementPaths(paths = [], $route) {
      const path = $route.path
      for (const match of paths) {
        // Match $route path
        const results = match.regexp.exec(path)
        if (results && results.length > 0) {
          return true
        }
      }
      return false
    },
    refreshActiveAnnouncement(announcements, $route) {
      this.active_announcements = _.filter(announcements, (announce) => {
        // Check matched path
        if (!this.validateAnnouncementPaths(announce.paths, $route))
          return false

        const now = moment()
        // Between start and end date
        if (announce.start_date && announce.end_date) {
          return now.isBetween(
            announce.start_date,
            moment(announce.end_date).endOf("day")
          )
        }
        // Before end date
        if (!announce.start_date) {
          return now.isBefore(moment(announce.end_date).endOf("day"))
        }
        // After start date
        return now.isAfter(announce.start_date)
      })

      // Show the latest announcement first (by start_date)
      this.active_announcements.sort((a, b) => {
        return new Date(b.start_date) - new Date(a.start_date)
      })

      // Determine which announcements are unread from cookie
      const saveReadIds = this.$cookies.get("announcement") || ""
      this.read_announcement_ids = new Set(
        typeof saveReadIds === "string"
          ? saveReadIds.split(",").filter((id) => id.length > 0)
          : []
      )
      const hasUnread =
        this.active_announcements.filter((ann) => {
          const id = `${ann.start_date}`
          return !this.read_announcement_ids.has(id)
        }).length > 0

      // Show dialog only when there're active, unread announcements
      if (!_.isEmpty(this.active_announcements) && hasUnread) {
        this.dialog = true
        this.handleChangeCarousel(0)
      }
    },
    handleChangeCarousel(index_new, index_old) {
      this.current_show = index_new
      this.previous_data = _.get(
        this.active_announcements,
        `[${index_new - 1}]`,
        {}
      )
      this.next_data = _.get(
        this.active_announcements,
        `[${index_new + 1}]`,
        {}
      )
    },
    handleClose() {
      // Mark active announcements as read and save to cookie
      const markReadAnnouncementIds = this.active_announcements.map(
        (a) => `${a.start_date}`
      )
      // Union of two sets
      this.read_announcement_ids = new Set([
        ...this.read_announcement_ids,
        ...markReadAnnouncementIds,
      ])
      const saveReadIds = Array.from(this.read_announcement_ids).join(",")
      this.$cookies.set("announcement", saveReadIds, { path: "/" })
    },
    handleControl(dri) {
      const el = _.get(this.$refs, "carousel")
      if (!el) return
      if (dri == "prev") {
        el.prev()
      } else {
        el.next()
      }
    },
    handleActive(index) {
      const el = _.get(this.$refs, "carousel")
      if (!el) return
      el.setActiveItem(index)
    },
  },
}
