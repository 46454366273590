const state = () => ({
  messenger: require("~/assets/images/icon-messenger.svg"),
  line: require("~/assets/images/icon-line-oa.svg"),
  line_oa_outline: require("~/assets/images/icon-line-oa-outline.svg"),
  line_app: require("~/assets/images/icon-line.svg"),
  facebook: require("~/assets/images/icon-facebook.svg"),
  instagram: require("~/assets/images/icon-instagram.svg"),
  tiktok: require("~/assets/images/icon-tiktok.svg"),
  tiktok_outline: require("~/assets/images/icon-tiktok-outline.svg"),
  instagram_lite: require("~/assets/images/icon-instagram-lite.svg"),
  messenger_outline: require("~/assets/images/icon-messenger-outline.svg"),
  line_outline: require("~/assets/images/icon-line-outline.svg"),
  facebook_outline: require("~/assets/images/icon-facebook-outline.svg"),
  auto_assign_team: require("~/assets/images/icon-auto-assign-team.svg"),
  auto_assign_chat_status: require("~/assets/images/icon-auto-assign-chat-status.svg"),
  end_case: require("~/assets/images/icon-end-case.svg"),
  bot_reply: require("~/assets/images/icon-bot-reply.svg"),
  bot_not_reply: require("~/assets/images/icon-bot-not-reply.svg"),
  warning_icon: require("~/assets/images/icon-warning.svg"),
  carousel_icon: require("~/assets/images/icon-carousel.svg"),
})

export default { state }
