
import { TAG_COMPONENT_BY_TYPES } from "../../constants/tag"

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "tag_default", // "tag_contact" | "tag_chat" | "tag_case" | "tag_broadcast" | "tag_default"
    },
    is_limit_exceeded: {
      type: Boolean,
      default: false,
    },
    show_tag_button: {
      type: Boolean,
      default: false,
    },
    show_untag_button: {
      type: Boolean,
      default: false,
    },
    show_edit_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tag_component_by_types: TAG_COMPONENT_BY_TYPES,
      hover_index: null,
    }
  },
  computed: {
    component_type() {
      return (
        this.tag_component_by_types[this.type] ??
        this.tag_component_by_types.tag_deafult
      )
    },
  },
  methods: {
    onClickTag(data) {
      if (data.checked) return
      if (!this.show_tag_button) return
      if (this.is_limit_exceeded) return
      this.$emit("onClickTag", data)
    },
    onClickUntag(data) {
      this.$emit("onClickUntag", data)
    },
    onClickEdit(data) {
      this.$emit("onClickEdit", data)
    },
    onMouseEnter(index) {
      this.hover_index = index
    },
    onMouseLeave() {
      this.hover_index = null
    },
  },
}
