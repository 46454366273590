const state = () => ({
  colors: [
    "#FFE2DD",
    "#F5E0E9",
    "#E8DEEE",
    "#D3E5EF",
    "#DBEDDB",
    "#FDECC8",
    "#FADEC9",
    "#EEE0DA",
    "#E3E2E0",
    "#F1F0F0",
  ],
  list: [],
})

const mutations = {
  setTeamList(state, data) {
    state.list = data
  }
}

export default { state, mutations }
