
import _ from "lodash"
import { mapState, mapGetters } from "vuex"

export default {
  props: {
    handleClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      collapse_value: [],
      load_preset_data: false,
      child_menu_collapse_value: [],
      abbreviation_number_boundary: 10000,
      child_collapse_value: [],
      team_menu_collapse: [],
      content_height: 0,
      child_collapse_content: {},
    }
  },
  created() {
    const side_menu_active_collapse =
      JSON.parse(localStorage.getItem("oho_side_menu_active_collapse")) || []

    this.child_collapse_value = [...side_menu_active_collapse] || []
    this.team_menu_collapse = [...side_menu_active_collapse] || []
  },
  computed: {
    ...mapState({
      disabled_active_menu: (state) => state.menu.disabled_active_menu,
      icon: (state) => state.icon,
      oho_member: (state) => state.oho_member,
    }),
    ...mapGetters(["nav_menu"]),
    current_menu() {
      const my_role = _.get(this.oho_member, "member.role")

      const menu = this.nav_menu.find((m) => m.path_id === this.path_name)

      // กรอง menu ของ agent ให้แสดงเฉพาะ "แชทของคุณ" กับ "แชทปักหมุด"
      if (this.path_name === "smartchat" && my_role === "agent") {
        menu.sub_menu_list = [menu.sub_menu_list[0]]

        if (menu.sub_menu_list[0].childs.length > 0) {
          menu.sub_menu_list[0].childs = menu.sub_menu_list[0].childs.filter(
            (item) => item.name !== "แชทที่ส่งให้ทีม"
          )
        }
      }

      return menu || {}
    },
    route_params() {
      return new URLSearchParams(this.$route.fullPath.split("?")[1])
    },
    is_onboarding() {
      return this.$route.path.split("/")[3] === "onboarding"
    },
    path_name() {
      return this.$route.path.split("/")[3]
    },
    route_path() {
      // return `/${this.$route.name.split("-biz_id-")[1]}`

      return this.is_onboarding
        ? `/${this.$route.path.split("/")[4]}`
        : this.$route.path
    },
    chatbot_diagram_view_permission() {
      return this.$permission.validate("chatbot.diagram.view")
    },
  },
  watch: {
    "current_menu.sub_menu_list": {
      immediate: true,
      deep: true,
      handler(new_value = []) {
        const is_contact_path = this.$route.path.split("/")[3] === "contact"

        if (is_contact_path && !this.load_preset_data) {
          this.load_preset_data = true
        }

        if (!is_contact_path) {
          this.load_preset_data = false
        }

        this.collapse_value = (new_value || []).map((m) => m.name)

        this.$nextTick(() => {
          new_value.forEach((main_menu) => {
            const childs_main_menu = _.get(main_menu, "childs", [])
            childs_main_menu.forEach((sub_menu) => {
              if (sub_menu.id) {
                this.initCollapseMenuContent(sub_menu.id)
              }
            })
          })
        })
      },
    },
  },
  methods: {
    initCollapseMenuContent(item_id) {
      this.child_collapse_content = document.querySelector(
        `#child_collapse_content_${item_id}`
      )
      if (this.child_collapse_content) {
        this.setContentWrapperHeight()

        if (this.isActiveCollapse(item_id)) {
          this.child_collapse_content.style.height = this.content_height + "px"
        }
      }
    },
    onOpenCollapse(item) {
      this.child_collapse_value.push(item.id)

      if (item.is_team_menu) {
        this.team_menu_collapse.push(item.id)
      }
    },
    onCloseCollapse(item) {
      const index = this.findCollapseItem(item.id)
      const team_menu_index = this.findTeamMenuItem(item.id)
      this.child_collapse_value.splice(index, 1)
      this.team_menu_collapse.splice(team_menu_index, 1)
    },
    findCollapseItem(id) {
      return this.child_collapse_value.findIndex((d) => d === id)
    },
    findTeamMenuItem(id) {
      return this.team_menu_collapse.findIndex((d) => d === id)
    },
    isActiveCollapse(id) {
      return this.findCollapseItem(id) >= 0
    },
    handleClickToggleCollapse(item) {
      this.child_collapse_content = document.querySelector(
        `#child_collapse_content_${item.id}`
      )

      this.setContentWrapperHeight()

      if (this.isActiveCollapse(item.id)) {
        this.onCloseCollapse(item)
        this.child_collapse_content.style.height = 0
      } else {
        this.onOpenCollapse(item)
        this.child_collapse_content.style.height = this.content_height + "px"
      }

      if (item.is_team_menu) {
        localStorage.removeItem("oho_side_menu_active_collapse")
        localStorage.setItem(
          "oho_side_menu_active_collapse",
          JSON.stringify(this.team_menu_collapse)
        )
      }
    },
    setContentWrapperHeight() {
      const content_wrapper = _.get(
        this.child_collapse_content.querySelectorAll(".content-wrapper"),
        "[0]"
      )

      if (content_wrapper) {
        this.content_height = content_wrapper.clientHeight
      }
    },
    selectPage(child) {
      if (this.path_name === "smartchat") {
        const child_params = new URLSearchParams(child.path.split("?")[1])
        const query = {
          status: child_params.get("status"),
          room: this.route_params.get("room"),
          assignee: child_params.get("assignee"),
          assign_to: child_params.get("assign_to"),
          team: child_params.get("team"),
          "assign-type": child_params.get("assign-type"),
        }
        this.$router.push({
          path: this.route_path,
          query: _.omitBy(query, _.isNil),
        })

        if (!["agent", "assignee"].includes(child_params.get("status"))) {
          this.child_menu_collapse_value = []
        }
      } else {
        this.$router.push(child.path)
      }
      this.visible_drawer = false
    },
    isPageActive(child) {
      if (this.disabled_active_menu) return false

      if (this.path_name === "smartchat") {
        const child_params = new URLSearchParams(child.path.split("?")[1])

        return (
          this.route_params.get("assignee") === child_params.get("assignee") &&
          this.route_params.get("status") === child_params.get("status") &&
          this.route_params.get("team") === child_params.get("team") &&
          this.route_params.get("assign-type") ===
            child_params.get("assign-type")
        )
      }

      if (["team", "integration"].includes(this.$route.path.split("/")[4])) {
        return this.$route.path.split("/")[4] === _.split(child.path, "/")[4]
      }

      return _.split(this.$route.path, "?")[0] === _.split(child.path, "?")[0]
    },
  },
}
