import AutoDetectService from "../services/auto-detect.service"
import _ from "lodash"

// instance of all service class
export default function ({ app, store }, inject) {
  const accessToken = _.get(store, "state.oho_member.accessToken")
  const autoDetectService = new AutoDetectService({ accessToken })

  inject("autoDetectService", autoDetectService)
}
