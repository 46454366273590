import _ from "lodash"

const BKK_POSTCODE = 10
const LOC_MAPPING_KEY = [
  {
    exec_key: "subDistrictName",
    code_key: "subDistrictCode",
    req_key: "sub_district_id",
    input_id: "sub_district",
    auto_detect_key: "subdistrict",
  },
  {
    exec_key: "districtName",
    code_key: "districtCode",
    req_key: "district_id",
    input_id: "district",
    auto_detect_key: "district",
  },
  {
    exec_key: "provinceName",
    code_key: "provinceCode",
    req_key: "province_id",
    input_id: "province",
    auto_detect_key: "province",
  },
  {
    exec_key: "postalCode",
    code_key: "postalCode",
    req_key: "postcode",
    input_id: "postcode",
    auto_detect_key: "postcode",
  },
  {
    input_id: "street",
    req_key: "street",
    auto_detect_key: "street_address",
  },
]

const getLocationMappingKey = () => {
  return LOC_MAPPING_KEY
}
const getUserName = (input_editor = [], placeholder = "") => {
  const first_name =
    _.chain(input_editor).find(["id", "first_name"]).get("value").value() || ""

  const last_name =
    _.chain(input_editor).find(["id", "last_name"]).get("value").value() || ""

  const display_name = `${first_name || ""} ${last_name || ""}`.trim()

  return display_name ? display_name : placeholder
}

const getAddressById = (input_editor = [], id = "") => {
  if (_.isEmpty(input_editor) || _.isEmpty(id)) return

  return _.chain(input_editor).find(["id", id]).get("value").value() || ""
}

const getAddress = (address_inputs = [], placeholder = "") => {
  let street = getAddressById(address_inputs, "street")
  let sub_district = getAddressById(address_inputs, "sub_district")
  let district = getAddressById(address_inputs, "district")
  let province = getAddressById(address_inputs, "province")
  let postcode = getAddressById(address_inputs, "postcode")
  let country = getAddressById(address_inputs, "country")

  let execute_data = {}

  if (!_.isEmpty(sub_district)) execute_data["subDistrictName"] = sub_district
  if (!_.isEmpty(district)) execute_data["districtName"] = district
  if (!_.isEmpty(province)) execute_data["provinceName"] = province
  if (!_.isEmpty(postcode)) execute_data["postalCode"] = Number(postcode)

  if (!_.isEmpty(execute_data)) {
    const address_fields = _.keys(execute_data)

    if (
      address_fields.length > 1 ||
      (address_fields.length === 1 && !_.includes(address_fields, "postalCode"))
    ) {
      let loc = $nuxt.$locationService.execute(execute_data)

      if (loc.length === 1) {
        let is_BKK = loc[0].provinceCode === BKK_POSTCODE
        _.forEach(address_fields, (field) => {
          let { input_id } = _.find(LOC_MAPPING_KEY, ["exec_key", field])

          if (input_id === "sub_district") {
            sub_district = `${is_BKK ? "แขวง" : "ตำบล"}${sub_district}`
          } else if (input_id === "district") {
            district = `${is_BKK ? "เขต" : "อำเภอ"}${district}`
          }
        })
      }

      province = province && loc.length >= 1 ? "จังหวัด" + province : province
    }
  }

  const address_values = [
    street,
    sub_district,
    district,
    province,
    postcode,
    country,
  ]

  const display_location = _.chain(address_values)
    .map((value) => (value ? ` ${value}` : ""))
    .value()
    .join("")
    .trim()

  return display_location ? display_location : placeholder
}

const getGender = (
  input_editor = {},
  value_key = "value",
  placeholder = ""
) => {
  if (
    _.isEmpty(input_editor) ||
    !_.includes(["value", "prev_value"], value_key)
  )
    return

  const display_gender =
    _.chain(input_editor.options)
      .find(({ value }) => value === input_editor[value_key])
      .get("name")
      .value() || ""

  return display_gender ? display_gender : placeholder
}

const contactProfileHelper = {
  getUserName,
  getAddress,
  getAddressById,
  getGender,
  getLocationMappingKey,
}

export default ({ app }, inject) => {
  inject("contactProfileHelper", contactProfileHelper)
}
