import _ from "lodash"
import { DefaultApi } from "@oho/ai-thai-ner"

class AutoDetectService {
  constructor({ basePath = "", accessToken = "" }) {
    /**
     * @param {string} basePath - The url path for call auto detect service.
     * @param {string} accessToken - The oho member token when come to business.
     */
    this.base_url = basePath || process.env.oho_ai_api_url

    this.access_token = accessToken || ""

    this.client = new DefaultApi({
      basePath: this.base_url,
      accessToken: this.access_token,
    })
  }

  /**
   * Requests a data extraction.
   * @param {string} body - The body request to call extraction service.
   * @param {Object} app_name - The source name that call service.
   * @returns {Promise<Object>} The response containing extraction result.
   * @throws {Error} Throws an error if the request fails.
   */
  async extract(body, app_name) {
    try {
      const res = await this.client.createItemExtractionsPost(body, app_name)

      return res
    } catch (error) {
      const error_message =
        _.get(error, "response.data.detail") || "ค้นหาข้อมูลไม่สำเร็จ"

      $nuxt.$logger.error(error_message)
      throw new Error(error_message)
    }
  }
  /**
   * Requests feedback extraction result.
   * @param {string} data - The data to feedback extraction result.
   * @param {Object} extraction_id - The extraction id for feedback.
   * @throws {Error} Throws an error if the request fails.
   */
  async feedbackExtraction(data, extraction_id) {
    try {
      await this.client.updateTransactionExtractionsFeedbackExtractionIdPut(
        data,
        extraction_id
      )
    } catch (error) {
      const error_message =
        _.get(error, "response.data.detail") || "อัพเดทข้อมูลไม่สำเร็จ"

      $nuxt.$logger.error(error_message)
      throw new Error(error_message)
    }
  }
  /**
   * Requests feedback that accept extraction result and allow auto fill.
   * @param {Object} extraction_id - The extraction id for feedback.
   * @throws {Error} Throws an error if the request fails.
   */
  async feedbackAutofillStatus(extraction_id) {
    try {
      await this.client.updateAutofillStatusExtractionsAutofillExtractionIdPut(
        {
          status: true,
        },
        extraction_id
      )
    } catch (error) {
      const error_message =
        _.get(error, "response.data.detail") ||
        "อัพเดทสถานะการกรอกข้อมูลอัตโนัมติไม่สำเร็จ"

      $nuxt.$logger.error(error_message)
      throw new Error(error_message)
    }
  }
}

export default AutoDetectService
