
import { mapGetters } from "vuex"

export default {
  props: {
    platform: {
      type: String,
      default: "facebook",
    },
    name: {
      type: String,
      default: "Username",
    },
    is_highlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getContactPlatformIcon"]),
    platform_icon() {
      return this.getContactPlatformIcon(this.platform)
    },
  },
}
