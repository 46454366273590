import _ from "lodash"
import moment from "moment"

const getFilterListCurrent = (filter_list, filter_type) => {
  return _.chain(filter_list)
    .find(["filter_type", filter_type])
    .get("filter_val", [])
    .value()
}

const getFilterValue = (filter_list, filter_type) => {
  return _.map(getFilterListCurrent(filter_list, filter_type), "value")
}

const isNotMatchChatListFilter = (contact_data, input_search, rootState) => {
  const contact_display_name = _.get(contact_data, "display_name", "")
  const contact_first_name = _.get(contact_data, "first_name", "")
  const contact_last_name = _.get(contact_data, "last_name", "")
  const contact_email = _.get(contact_data, "email", "")
  const contact_phone = _.get(contact_data, "phone", "")
  const customer_id = _.get(contact_data, "customer_id", "")
  const contact_channel_id =
    _.get(contact_data, "channel_id._id") ||
    _.get(contact_data, "channel_id", "")
  const contact_tags_id = _.chain(contact_data)
    .get("tags")
    .map((item) => item._id || item)
    .value()
  const referral_tags_id = _.chain(contact_data)
    .get("referrals")
    .map((item) => item._id || item)
    .value()
  const chat_tags_id = _.chain(contact_data)
    .get("labels")
    .map((item) => item._id || item)
    .value()
  const sale_owner = _.get(contact_data, "sale_owner", null)
  const sale_owner_id = _.get(sale_owner, "member_id._id", null)
  const transform_sale_owner = sale_owner_id ? [sale_owner_id] : [sale_owner]

  const regex = new RegExp(input_search, "i")
  const is_display_name_matches = contact_display_name.match(regex)
  const is_first_name_matches = contact_first_name.match(regex)
  const is_last_name_matches = contact_last_name.match(regex)
  const is_email_matches = contact_email.match(regex)
  const is_phone_matches = contact_phone.match(regex)
  const is_customer_id_matches = customer_id.match(regex)
  const is_not_in_channel_filter = !_.includes(
    rootState.checked_channels,
    contact_channel_id
  )

  const filtered_contact_tag = getFilterValue(
    rootState.filter.filter_chat_list,
    "contact_tag"
  )
  const filtered_referral_tag = getFilterValue(
    rootState.filter.filter_chat_list,
    "referral_tag"
  )
  const filtered_chat_tag = getFilterValue(
    rootState.filter.filter_chat_list,
    "chat_tag"
  )
  const filtered_sale_owner = getFilterValue(
    rootState.filter.filter_chat_list,
    "sale_owner"
  )

  const is_not_in_tag_contact_filter = !_.chain(filtered_contact_tag)
    .difference(contact_tags_id)
    .size()
    .eq(0)
    .value()
  const is_not_in_tag_referral_filter = !_.chain(filtered_referral_tag)
    .difference(referral_tags_id)
    .size()
    .eq(0)
    .value()
  const is_not_in_tag_chat_filter = !_.chain(filtered_chat_tag)
    .difference(chat_tags_id)
    .size()
    .eq(0)
    .value()
  const is_not_in_sale_owner_filter = !_.chain(filtered_sale_owner)
    .difference(transform_sale_owner)
    .size()
    .eq(0)
    .value()

  const is_keyword_not_matches = !(
    is_display_name_matches ||
    is_first_name_matches ||
    is_last_name_matches ||
    is_email_matches ||
    is_phone_matches ||
    is_customer_id_matches
  )

  return (
    is_not_in_channel_filter ||
    is_not_in_tag_contact_filter ||
    is_not_in_tag_referral_filter ||
    is_not_in_tag_chat_filter ||
    is_not_in_sale_owner_filter ||
    is_keyword_not_matches
  )
}

/**
- ในกรณี filter เลือกพนักงานขาย 1 คน จะทำให้ filter_value = ['id'] ส่งผลให้ _.compact(sale_owner_ids).length > 0 เสมอ แล้ว query จะเป็น "owner.member_id[$in]": ['id'] ไป
- ในกรณี filter แต่ไม่เลือกพนักงานขาย จะทำให้ filter_value = [] ส่งผลให้ sale_owner_ids.length = 0 แล้ว query จะเป็น owner: undefined ซึ่งเปรียบเสมือนไม่ได้ส่ง query owner ไป
- ในกรณี filter เลือกพนักงานขายแบบ ไม่ระบุ จะทำให้ filter_value = [null] ส่งผลให้ sale_owner_ids.length > 0 แล้ว query จะเป็น owner: "null" จึงจะให้ API ส่งข้อมูลมาได้ถูกต้อง
**/
const saleOwnerQueryParams = (sale_owner_ids) => {
  let params

  if (_.compact(sale_owner_ids).length > 0) {
    params = sale_owner_ids[0]
  } else {
    params = sale_owner_ids.length === 0 ? undefined : "null"
  }

  return { sale_owner: params }
}

const filterHelper = {
  handleSetDateRangeByDaysAgo: (day) => {
    let date_period = undefined
    if (day) {
      const start = moment()
        .startOf("date")
        .subtract(+day, "d")
        .format()
      const end = moment().endOf("date").format()
      date_period = [start, end]
    }
    return date_period
  },
  getFilterListCurrent,
  getFilterValue,
  isNotMatchChatListFilter,
  saleOwnerQueryParams,
}

export default ({ app }, inject) => {
  inject("filterHelper", filterHelper)
}
