import _ from "lodash"
import ep from "~/api/endpoint"
import axios from "axios"

class FileService {
  constructor() {
    /**
     * List of files currently being uploaded.
     * @type {Array<Object>}
     */
    this.upload_list = []
  }

  /**
   * Requests a signed URL from the API for file upload.
   *
   * @param {string} file_name - The name of the file to be uploaded.
   * @param {Object} cancel_upload - Axios CancelToken to cancel the request.
   * @returns {Promise<Object>} The response containing the signed URL.
   * @throws {Error} Throws an error if the request fails.
   */
  async getSignedURL(file_name, cancel_upload) {
    try {
      const res = await $nuxt.$ohoMemberApi.$post(
        ep.file_upload_link,
        {
          name: file_name,
        },
        {
          cancelToken: cancel_upload.token,
        }
      )

      return res
    } catch (error) {
      $nuxt.$logger.error(error)
      throw new Error(
        _.get(error, "response.data") || "ขอ Signed URL ไม่สำเร็จ"
      )
    }
  }

  /**
   * Uploads a file to the server using the provided signed URL.
   *
   * @param {string} signed_url - The signed URL for the file upload.
   * @param {File} raw_file - The raw file object to be uploaded.
   * @param {Object} cancel_upload - Axios CancelToken to cancel the request.
   * @returns {Promise<Object>} The response after the file is successfully uploaded.
   * @throws {Error} Throws an error if the upload fails.
   */
  async uploadFileWithSignedURL(signed_url, raw_file, cancel_upload) {
    try {
      /**
       * avoid to reuse $axios.$put because of when user login with facebook account
       * then the system automatically set default "Authorization" Bearer to header
       * that face to CORS error when uploading file with Signed URL
       */
      const res = await axios.put(signed_url, raw_file, {
        headers: {
          "x-goog-acl": "public-read",
          "Content-Type": raw_file.type,
          "x-goog-meta-method": "signed_url",
        },
        cancelToken: cancel_upload.token,
      })

      return res
    } catch (error) {
      $nuxt.$logger.error(error)
      throw new Error(_.get(error, "response.data") || "อัพโหลดไฟล์ไม่สำเร็จ")
    }
  }

  /**
   * Initiates the upload process for a given file.
   *
   * @param {Object} file - The file object containing details of the file to be uploaded.
   * @returns {Promise<Object>} The response after the file is successfully uploaded.
   * @throws {Error} Throws an error if the upload process fails.
   */
  async upload(file) {
    const axios_cancel_token = axios.CancelToken
    const cancel_upload = axios_cancel_token.source()

    this.upload_list.push({
      uid: file.uid,
      name: file.name,
      cancel_upload: cancel_upload,
    })

    try {
      const raw_file = _.get(file, "raw")
      const file_name = _.get(raw_file, "name")
      const uploaded_file = await this.getSignedURL(file_name, cancel_upload)

      if (uploaded_file) {
        const signed_url = _.get(uploaded_file, "upload_link.signed_url")
        await this.uploadFileWithSignedURL(signed_url, raw_file, cancel_upload)
        return uploaded_file
      }
    } catch (error) {
      $nuxt.$logger.error(error)
      throw new Error(_.get(error, "response.data") || "อัพโหลดไฟล์ไม่สำเร็จ")
    } finally {
      const file_index = this.upload_list.findIndex(
        (item) => item.uid === file.uid
      )
      if (file_index > -1) {
        this.removeFileFromUploadList(file_index)
      }
    }
  }

  /**
   * Cancels an ongoing file upload.
   *
   * @param {Object} file_data - The file object containing the details of the file to be canceled.
   */
  cancelUpload(file_data) {
    const file_index = this.upload_list.findIndex(
      (item) => item.uid === file_data.uid
    )
    if (file_index > -1) {
      const file = this.upload_list[file_index]
      file.cancel_upload.cancel("User canceled the file upload")
      this.removeFileFromUploadList(file_index)
    }
  }

  /**
   * Removes a file from the upload list.
   *
   * @param {number} file_index - The index of the file to be removed from the upload list.
   */
  removeFileFromUploadList(file_index) {
    this.upload_list.splice(file_index, 1)
  }
}

export default FileService
