import {
  AUTO_DETECT_ATTRIBUTES,
  AUTO_DETECT_APP_NAME,
} from "../../constants/auto-detect"

const state = () => ({
  is_disabled_auto_detect: false,
  hover_bubble_id: null,
  current_detect_message: {},
  detect_data: {
    is_loading: false,
    is_success: false,
    is_error: false,
    response_data: {},
    saved_detect_data: {},
  },
})

const mutations = {
  setDisabledAutoDetect(state, data) {
    state.is_disabled_auto_detect = data
  },
  setAutoDetectBubbleHoverID(state, id) {
    state.hover_bubble_id = id
  },
  setAutoDetectState(state, data) {
    state.detect_data = data
  },
  hideAutoDetectPanel(state) {
    state.detect_data.is_success = false
    state.detect_data.is_error = false
  },
  setCurrentDetectMessage(state, message) {
    state.current_detect_message = message
  },
  resetAutoDetectData(state) {
    state.is_disabled_auto_detect = false
    state.hover_bubble_id = null
    state.current_detect_message = {}
    state.detect_data = {
      is_loading: false,
      is_success: false,
      is_error: false,
      response_data: {},
      saved_detect_data: {},
    }
  },
}

const actions = {
  async autoDetectInfo({ commit }, current_message) {
    try {
      commit("setCurrentDetectMessage", current_message)
      commit("setAutoDetectState", {
        is_loading: true,
        is_success: false,
        is_error: false,
      })
      commit("setDisabledAutoDetect", true)

      const res = await $nuxt.$autoDetectService.extract(
        {
          text: current_message.text || "",
          attributes: AUTO_DETECT_ATTRIBUTES,
          metadata: current_message.metadata,
        },
        AUTO_DETECT_APP_NAME
      )
      const data = _.get(res, "data")

      commit("setAutoDetectState", {
        is_loading: false,
        is_success: true,
        is_error: false,
        response_data: data,
      })
      commit("setDisabledAutoDetect", false)
    } catch (error) {
      commit("setAutoDetectState", {
        is_loading: false,
        is_success: false,
        is_error: true,
      })
      commit("setDisabledAutoDetect", false)
      this.$logger.error(error)
    } finally {
      // Scroll the auto-detect panel into view
      const message_wrap_el = document.querySelector(
        `#message_wrap_${current_message.id}`
      )

      if (message_wrap_el) {
        setTimeout(() => {
          message_wrap_el.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          })
        }, 100)
      }
    }
  },
  async feedbackAutoDetectInfo({ commit }, { data, extraction_id }) {
    try {
      await $nuxt.$autoDetectService.feedbackExtraction(data, extraction_id)
    } catch (error) {
      this.$logger.error(error)
    }
  },
  async feedbackAutoFillInfoStatus({ commit }, extraction_id) {
    try {
      await $nuxt.$autoDetectService.feedbackAutofillStatus(extraction_id)
    } catch (error) {
      this.$logger.error(error)
    }
  },
}

export default { state, mutations, actions }
