const query_params = {
  contact_default: {
    "$populate[0][path]": "notes",
    "$populate[0][populate][0][path]": "created_by",
    "$populate[0][populate][0][select]": "display_name",
    "$populate[0][populate][1][path]": "updated_by",
    "$populate[0][populate][1][select]": "display_name",
    "$populate[0][populate][2][path]": "attachments",
    "$populate[1][path]": "profile_info_updated_by",
    "$populate[1][select]": "display_name",
    "$populate[2][path]": "channel_id",
    "$populate[3][path]": "attributes.attribute_id",
    "$populate[4][path]": "labels",
    "$populate[5][path]": "tags",
    "$populate[6][path]": "current_case",
    "$populate[6][populate][0][path]": "tags_migrate_objectid",
    "$populate[6][populate][0][select]": "keyword",
    "$populate[7][path]": "assignee.member_id",
    "$populate[7][populate][0][path]": "teams",
    "$populate[7][populate][0][select]": [
      "display_name",
      "logo_url",
      "color_hex_code",
      "type",
    ],
    "$populate[8][path]": "assignee.team_id",
    "$populate[9][path]": "assign_to.member_id",
    "$populate[9][populate][0][path]": "teams",
    "$populate[9][populate][0][select]": [
      "display_name",
      "logo_url",
      "color_hex_code",
      "type",
    ],
    "$populate[10][path]": "assign_to.team_id",
    "$populate[11][path]": "assign_by.member_id",
    "$populate[11][populate][0][path]": "teams",
    "$populate[11][populate][0][select]": [
      "display_name",
      "logo_url",
      "color_hex_code",
      "type",
    ],
    "$populate[12][path]": "assign_by.team_id",
    "$populate[13][path]": "sale_owner.member_id",
    "$populate[13][select]": [
      "display_name",
      "role",
      "online_status",
      "image_url",
      "is_deactivated",
      "teams",
    ],
    "$populate[14][path]": "sale_owner.team_id",
    "$populate[14][select]": [
      "display_name",
      "logo_url",
      "color_hex_code",
      "type",
      "sale_visibility",
      "is_deactivated",
    ],
    "$populate[15][path]": "sale_owner_updated_by",
    "$populate[15][select]": ["display_name"],
    "$populate[16][path]": "referrals",
  },
  contact_table: {
    "$populate[0][path]": "channel_id",
    "$populate[1][path]": "labels",
    "$populate[2][path]": "tags",
    "$populate[3][path]": "all_case",
    "$populate[3][populate][path]": "assignee_member",
    "$populate[3][populate][populate][0]": "member_id",
    "$populate[4][path]": "sale_owner.member_id",
    "$populate[4][select]": [
      "display_name",
      "role",
      "online_status",
      "image_url",
      "is_deactivated",
      "teams",
    ],
    "$populate[5][path]": "sale_owner.team_id",
    "$populate[5][select]": [
      "display_name",
      "logo_url",
      "color_hex_code",
      "type",
      "sale_visibility",
      "is_deactivated",
    ],
    "$populate[6][path]": "sale_owner_updated_by",
    "$populate[6][select]": ["display_name"],
    "$populate[7][path]": "referrals",
  },
  contact_by_id: {
    "$populate[0][path]": "notes",
    "$populate[0][populate][0][path]": "created_by",
    "$populate[0][populate][0][select]": "display_name",
    "$populate[0][populate][1][path]": "updated_by",
    "$populate[0][populate][1][select]": "display_name",
    "$populate[0][populate][2][path]": "attachments",
    "$populate[1][path]": "assignee.member_id",
    "$populate[1][select]": ["display_name", "image_url"],
    "$populate[2][path]": "profile_info_updated_by",
    "$populate[2][select]": "display_name",
    "$populate[3][path]": "channel_id",
    "$populate[4][path]": "labels",
    "$populate[5][path]": "tags",
    "$populate[6][path]": "attributes.attribute_id",
    "$populate[7][path]": "sale_owner.member_id",
    "$populate[7][select]": [
      "display_name",
      "role",
      "online_status",
      "image_url",
      "is_deactivated",
      "teams",
    ],
    "$populate[8][path]": "sale_owner.team_id",
    "$populate[8][select]": [
      "display_name",
      "logo_url",
      "color_hex_code",
      "type",
      "sale_visibility",
      "is_deactivated",
    ],
    "$populate[9][path]": "sale_owner_updated_by",
    "$populate[9][select]": ["display_name"],
    "$populate[10][path]": "referrals",
  },
  chat_action: {
    "$populate[0][path]": "current_case",
    "$populate[0][populate][0][path]": "tags_migrate_objectid",
    "$populate[0][populate][0][select]": "keyword",
    "$populate[1][populate][0][path]": "teams",
    "$populate[1][path]": "assignee.member_id",
    "$populate[1][populate][0][path]": "teams",
    "$populate[1][populate][0][select]": [
      "display_name",
      "logo_url",
      "color_hex_code",
      "type",
    ],
    "$populate[2][path]": "assignee.team_id",
    "$populate[3][path]": "assign_to.member_id",
    "$populate[3][populate][0][path]": "teams",
    "$populate[3][populate][0][select]": [
      "display_name",
      "logo_url",
      "color_hex_code",
      "type",
    ],
    "$populate[4][path]": "assign_to.team_id",
    "$populate[5][path]": "assign_by.member_id",
    "$populate[5][populate][0][path]": "teams",
    "$populate[5][populate][0][select]": [
      "display_name",
      "logo_url",
      "color_hex_code",
      "type",
    ],
    "$populate[6][path]": "assign_by.team_id",
    "$populate[7][path]": "labels",
    "$populate[7][select]": ["group", "color_hex_code", "keyword"],
    "$populate[8][path]": "tags",
    "$populate[8][select]": ["group", "keyword"],
    "$populate[9][path]": "referrals",
  },
}

export default query_params
