export const TAG_CHAT_COLORS = {
  RED: "#FF8787",
  BLUE: "#80B2FF",
  GREEN: "#7DDA6E",
  YELLOW: "#DAB56E",
  PURPLE: "#B486FF",
}

export const TAG_CHAT_DEFAULT_COLOR = TAG_CHAT_COLORS.RED

export const TAG_TYPES = {
  tag_contact: "tag_contact",
  tag_chat: "tag_chat",
  tag_case: "tag_case",
  tag_broadcast: "tag_broadcast",
  tag_default: "tag_default",
}

export const TAG_TYPE_BY_GROUPS = {
  tag: "tag_contact",
  contact_label: "tag_chat",
  case_tag: "tag_case",
  broadcast: "tag_broadcast",
  tag_default: "tag_default",
}

export const TAG_GROUP_BY_TYPES = {
  tag_contact: "tag",
  tag_chat: "contact_label",
  tag_case: "case_tag",
  tag_broadcast: "broadcast",
  tag_default: "tag_default",
}

export const TAG_COMPONENT_BY_TYPES = {
  tag_contact: "TagContact",
  tag_chat: "TagChat",
  tag_case: "TagCase",
  tag_broadcast: "TagBroadcast",
  tag_deafult: "TagDefault",
}
