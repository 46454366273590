
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show_remove_dialog: false,
    }
  },
  watch: {
    visible: {
      handler(val) {
        this.show_remove_dialog = val
      },
      immediate: true,
    },
  },
  methods: {
    onClickConfirm(data) {
      this.$emit("onClickConfirm", data)
    },
    onClickCancel() {
      this.$emit("onClickCancel")
    },
    onBeforeCloseDialog() {
      this.$emit("onCloseDialog")
    },
  },
}
