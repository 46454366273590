
import _ from "lodash"

export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    contact_id: {
      type: String,
      default: "",
    },
    show_untag_button: {
      type: Boolean,
      default: false,
    },
    onUntag: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      all_data: [],
      paginated_data: [],
      current_page: 1,
      item_per_page: 10,
      total_page: 0,
      should_animate: false,
    }
  },
  watch: {
    tags: {
      handler(next_val = [], prev_val = []) {
        const next_referral_ids = next_val.map((item) => item._id)
        const prev_referral_ids = prev_val.map((item) => item._id)

        if (!_.isEqual(next_referral_ids, prev_referral_ids)) {
          this.all_data = _.cloneDeep(next_val)
          this.init()
        }
      },
      immediate: true,
      deep: true,
    },
    contact_id: {
      handler() {
        this.init()
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    is_has_more_data() {
      return this.current_page < this.total_page
    },
  },
  methods: {
    init() {
      this.reset()
      this.total_page = Math.ceil(this.all_data.length / this.item_per_page)
      this.paginate()
    },
    reset() {
      this.paginated_data = []
      this.current_page = 1
      this.item_per_page = 10
      this.total_page = 0
    },
    paginate() {
      const calculate_start =
        this.all_data.length - this.current_page * this.item_per_page
      const start = calculate_start > 0 ? calculate_start : 0
      const end = calculate_start + this.item_per_page
      const more_data = this.all_data.slice(start, end)
      this.paginated_data.splice(0, 0, ...more_data)
    },
    loadMore() {
      if (this.is_has_more_data) {
        this.should_animate = true
        this.current_page++
        this.paginate()
        setTimeout(() => {
          this.should_animate = false
        }, 1000)
      }
    },
  },
}
