const state = () => ({
  active_tab: "contact"
})

const mutations = {
  setMaxPanelActiveTab(state, tab) {
    state.active_tab = tab
  }
}

export default { state, mutations }
