import _ from "lodash"

const attachmentHelper = {
  getFileType: (file = {}) => {
    const type = _.get(file, "type", "")
    if (type === "unknown") {
      const type_from_content_type = _.chain(file)
        .get("content_type")
        .split("/")
        .get("[0]")
        .value()
      switch (type_from_content_type) {
        case "image":
          return "image"
        case "video":
          return "video"
        default:
          return "file"
      }
    } else if (type) {
      return file.type
    } else {
      return "file"
    }
  },
}

export default ({ app }, inject) => {
  inject("attachmentHelper", attachmentHelper)
}
